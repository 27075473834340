
































































































































































































import { Api, isAxiosError, NonCompliantSubType, PagedResults, SchoolOrgType, TableState } from '@/edshed-common/api'
import Component from 'vue-class-component'
import ComponentHelper from '@/mixins/ComponentHelper'
import { Mixins } from 'vue-property-decorator'
import SubscriptionTags from '@/edshed-common/components/SubscriptionTags.vue'

const Operators = ['<', '<=', '=', '>=', '>'] as const
type Operators = typeof Operators[number]

@Component({
  name: 'NonCompliants',
  components: {
    SubscriptionTags
  }
})
export default class NonCompliants extends Mixins(ComponentHelper) {
  loading: boolean = false

  SchoolOrgType = SchoolOrgType

  data: PagedResults<any> = {
    items: [],
    total: 0
  }

  table: TableState = {
    page: 1,
    perPage: 10,
    sort: 'id',
    dir: 'asc',
    term: ''
  }

  filterSubType: NonCompliantSubType[] = []

  filterOrgType: SchoolOrgType[] = []

  losingAccessType: 'dl' | 'no-dl' = 'dl'

  Operators = Operators

  licenceFilterOperator: null | Operators = null

  licenceFilterValue: number = 36

  thresholdFilterOperator: null | Operators = null

  thresholdFilterValue: number = 10

  downloadFilterOperator: null | Operators = null

  downloadFilterValue: number = 90

  mounted () {
    this.getData()
  }

  downloadTypeChanged (type: 'dl' | 'no-dl') {
    const column = type === 'dl' ? 'losing_access_with_downloads' : 'losing_access'
    const otherColumn = type === 'no-dl' ? 'losing_access_with_downloads' : 'losing_access'

    if (this.table.sort === otherColumn) {
      this.sortChanged(column, this.table.dir)
    }
  }

  async getData () {
    try {
      this.loading = true
      this.data = await Api.useNewQueryFormat().getNonCompliantSubs({
        skip: (this.table.page - 1) * this.table.perPage,
        take: this.table.perPage,
        sort: this.table.sort,
        dir: this.table.dir,
        term: this.table.term
      }, {
        sub_type: this.filterSubType.length === 0 ? undefined : this.filterSubType,
        org_type: this.filterOrgType.length === 0 ? undefined : this.filterOrgType,
        license_count: this.licenceFilterOperator === null ? undefined : {
          operator: this.licenceFilterOperator,
          value: this.licenceFilterValue
        },
        losing_access: this.thresholdFilterOperator === null ? undefined : {
          operator: this.thresholdFilterOperator,
          type: this.losingAccessType === 'dl' ? 'with-downloads' : 'without-downloads',
          value: this.thresholdFilterValue / 100
        },
        downloads: this.downloadFilterOperator === null ? undefined : {
          operator: this.downloadFilterOperator,
          value: this.downloadFilterValue
        }
      })
    } catch (err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Could not load subscriptions',
        position: 'is-bottom',
        type: 'is-danger'
      })
    } finally {
      this.loading = false
    }
  }

  async turnOnForSub (id: number) {
    try {
      this.loading = true
      await Api.updateTeachersInferredAccess(id, true)
      await this.getData()
    } catch (err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Could not turn on',
        position: 'is-bottom',
        type: 'is-danger'
      })
    } finally {
      this.loading = false
    }
  }

  pageChanged (page: number) {
    this.table.page = page
    this.getData()
  }

  sortChanged (sort: string, order: 'asc' | 'desc') {
    this.table.sort = sort
    this.table.dir = order
    this.getData()
  }

  refreshClicked () {
    this.$buefy.dialog.confirm({
      title: 'Refresh Cache',
      message: 'The data displayed on this page is cached due to its complexity. This means that the data might not be 100% accurate. It only takes a few minutes to refresh the cache.',
      confirmText: 'Refresh Cache',
      type: 'is-warning',
      hasIcon: true,
      onConfirm: async () => {
        this.loading = true

        try {
          this.$buefy.toast.open({
            message: 'Cache is refreshing in background',
            type: 'is-info',
            position: 'is-bottom',
            duration: 3000
          })

          this.loading = false

          await Api.refreshNonCompliantSubsCache()

          this.$buefy.toast.open({
            message: 'Cache refreshed',
            type: 'is-success',
            position: 'is-bottom',
            duration: 3000
          })

          await new Promise(resolve => setTimeout(resolve, 200))

          this.getData()
        } catch (err: unknown) {
          if (isAxiosError(err)) {
            this.$buefy.toast.open({
              message: err.message,
              type: 'is-danger',
              position: 'is-bottom',
              duration: 3000
            })
          }

          this.loading = false
        }
      }
    })
  }
}
