





























































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { Locale, Subscription, SubscriptionProductType } from '../api'
import ComponentHelperBase from '../mixins/ComponentHelperBase'

@Component({
  name: 'SubscriptionTags'
})
export default class SubscriptionTags extends Mixins(ComponentHelperBase) {
  @Prop({ required: true }) sub!: Subscription

  @Prop({ required: true }) locale!: Locale

  @Prop({ default: false }) singleLine!: boolean

  @Prop({ default: true }) showMetered!: boolean

  @Prop({ default: true }) showTitle!: boolean

  shouldShowProductTag (product: SubscriptionProductType) {
    return this.sub.base_plan.product.product_type === product || this.sub.add_on_plans.find(a => a.product.product_type === product)
  }

  get shouldShowScope () {
    return this.sub.base_plan.scope.title.toLowerCase() !== 'any'
  }

  get shouldShowSpelling () {
    return this.shouldShowProductTag('spelling') || (this.shouldShowProductTag('freemium'))
  }

  get shouldShowNumber () {
    return this.shouldShowProductTag('number') || (this.shouldShowProductTag('freemium'))
  }
}
